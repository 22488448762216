import React, {Component} from "react";

interface DateRendererProps {
    value: any
}

class DateRenderer extends Component<DateRendererProps> {

    render() {
        let dateParts: string[] = [];
        if (this.props.value) {
            dateParts = this.props.value.split('-');
        }
        return (
            <span>
                {dateParts.length === 3 ? `${dateParts[1]}/${dateParts[2]}/${dateParts[0]}` : ''}
            </span>
        )
    }
}

export default DateRenderer;

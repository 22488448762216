import React from "react";
import { Route } from 'react-router';
import Home from "./Home";
import Accounts from "./accounts/accounts";
import ScrollToTop from "./ScrollTop";
import Forecast from "./forecast/Forecast";
import Schedule from "./schedule/Schedule";
import History from "./history/History";
import Projection from "./projections/Projections";
import SignIn from "./auth/SignIn";
import SignUp from "./auth/SignUp";
import VirtualForecast from "./forecast/VirtualForecast";

const Routes = () => (
    <ScrollToTop>
        <Route exact path='/' component={Home}/>
        <Route path="/forecast" component={Forecast}/>
        <Route path="/virt-forecast" component={VirtualForecast}/>
        <Route path="/accounts" component={Accounts} />
        <Route path='/schedule' component={Schedule} />
        <Route path='/history' component={History} />
        <Route path='/projection' component={Projection} />
        <Route path='/login' component={Home}/>
        <Route path='/signin' component={SignIn}/>
        <Route path='/signup' component={SignUp}/>
    </ScrollToTop>
);

export default Routes;

import {AxiosResponse} from 'axios';
import {api} from "./utils";


export type Account = {
    accountId: number,
    name: string,
    minimumBalance?: number,
    virtual?: boolean,
    retired?: boolean,
    parentAccount?: Account
}

export const EmptyAccount: Account = {
    accountId: 0,
    name: ''
}

const getAll = (): Promise<AxiosResponse<Account[]>> => api.get("/account");

const getAllWithRetired = (): Promise<AxiosResponse<Account[]>> => api.get('/account?includeRetired=true');

const get = (account_id: number): Promise<AxiosResponse<Account>> => api.get(`/account/${account_id}`);

const add = (account: Account): Promise<AxiosResponse<Account>> => api.post("/account", account);

const update = (account_id: number, account: Account): Promise<AxiosResponse<Account>> => api.put(`/account/${account_id}`, account);

const del = (account_id: number): Promise<AxiosResponse<Account>> => api.delete(`/account/${account_id}`);

const AccountService = {
    getAll,
    getAllWithRetired,
    get,
    add,
    update,
    del
};

export default AccountService;

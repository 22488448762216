import React, {useEffect, useState} from 'react';
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {makeStyles} from "@material-ui/core/styles";
import AccountService, {Account} from "../services/account.service";
import useAccountContext from "../providers/AccountContextProvider";

const useStyles = makeStyles((theme) => ({
    box: {
        marginBottom: theme.spacing() * 2
    },
    select: {
        fontSize: "1.25rem",
        fontWeight: 500,
        color: "inherit"
    }
}));

const AccountContext = () => {
    const classes = useStyles();

    const {setAccount} = useAccountContext();

    const [accounts, setAccounts] = useState<Account[]>([]);
    const [selectedAccount, setSelectedAccount] = useState<Account | null>(null);

    useEffect(() => {
        AccountService.getAll()
            .then(response => {
                setAccounts(response.data)
            })
            .catch(e => {
                console.error(e);
            })
        const storedAccountString = localStorage.getItem('selected-account');
        if (storedAccountString) {
            const storedAccount = JSON.parse(storedAccountString);
            setSelectedAccount(storedAccount);
        }
    }, []);

    useEffect(() => {
        if (selectedAccount) {
            setAccount(selectedAccount);
        }
    }, [selectedAccount, setAccount]);

    useEffect(() => {
        if (accounts.length === 1 && selectedAccount === null) {
            setSelectedAccount(accounts[0]);
            localStorage.setItem('selected-account', JSON.stringify(accounts[0]));
        }
    }, [accounts, selectedAccount])

    const onAccountChange = (event: React.ChangeEvent<{ value: unknown }>): void => {
        const account = accounts.filter(a => a.accountId === event.target.value)[0];
        setSelectedAccount(account);
        localStorage.setItem('selected-account', JSON.stringify(account));
    }

    return (
        <>
        {accounts && accounts.length > 1 && (
            <>
                <span>&nbsp;:&nbsp;</span>
                <Select
                    labelId="account-select-label"
                    id="account-select"
                    value={selectedAccount ? selectedAccount.accountId : ''}
                    onChange={onAccountChange}
                    className={classes.select}>
                    {accounts.map(a => (
                        <MenuItem key={a.accountId} value={a.accountId}>{a.name}</MenuItem>
                    ))}
                </Select>
            </>
        )}
        </>
    )
}

export default AccountContext;

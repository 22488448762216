import React, {useEffect, useState} from "react";
import {History} from '../../services/history.service';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

interface HistoryDialogProps {
    open: boolean,
    mode: 'ADD' | 'EDIT',
    onCancel: (() => void),
    onSave: ((history: History) => void),
    history: History
}

const HistoryDialog = (props: HistoryDialogProps) => {

    const [historyId, setHistoryId] = useState(props.history.historyId);
    const [sequence, setSequence] = useState(props.history.sequence);
    const [txnDate, setTxnDate] = useState(props.history.txnDate);
    const [name, setName] = useState(props.history.name);
    const [amount, setAmount] = useState(props.history.amount);
    const [inputAmount, setInputAmount] = useState(props.history.amount.toString());
    const [balance, setBalance] = useState(props.history.balance);
    const [inputBalance, setInputBalance] = useState(props.history.balance.toString());
    const [account, setAccount] = useState(props.history.account);

    useEffect(() => {
        setHistoryId(props.history.historyId);
        setAccount(props.history.account);
        setSequence(props.history.sequence);
        setTxnDate(props.history.txnDate);
        setName(props.history.name);
        setAmount(props.history.amount);
        setInputAmount(props.history.amount.toString());
        setBalance(props.history.balance);
        setInputBalance(props.history.balance.toString());
    }, [props.history]);

    const onSequenceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSequence(parseInt(event.target.value));
    };

    const onTxnDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        console.log(event.target.value);
        setTxnDate(event.target.value);
    };

    const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value);
    };

    const onAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInputAmount(event.target.value);

        const attemptedAmount = parseFloat(event.target.value);

        if (!Object.is(NaN, attemptedAmount)) {
            setAmount(parseFloat(event.target.value));
        }
    }

    const onBalanceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInputBalance(event.target.value);

        const attemptedBalance = parseFloat(event.target.value);

        if (!Object.is(NaN, attemptedBalance)) {
            setBalance(parseFloat(event.target.value));
        }
    }

    return (
        <Dialog open={props.open} onClose={props.onCancel} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{`${props.mode === 'ADD' ? 'Add' : 'Edit'} History`}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Enter the details for the transaction.
                </DialogContentText>
                <TextField margin="dense" id="historyId" label="History Id" type="number" fullWidth
                           value={historyId} disabled/>
                <TextField autoFocus margin="dense" id="sequence" label="Sequence" type="number" fullWidth
                           value={sequence} onChange={onSequenceChange}/>
                <TextField margin="dense" id="txnDate" label="Transaction Date" type="date" fullWidth
                           value={txnDate} onChange={onTxnDateChange} InputLabelProps={{ shrink: true }}/>
                <TextField margin="dense" id="name" label="Name" type="text" fullWidth
                           value={name} onChange={onNameChange}/>
                <TextField margin="dense" id="amount" label="Amount" type="text" fullWidth
                           value={inputAmount} onChange={onAmountChange}/>
                <TextField margin="dense" id="balance" label="Balance" type="text" fullWidth
                           value={inputBalance} onChange={onBalanceChange}/>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.onSave({
                    historyId, account, sequence, txnDate, name, amount, balance
                })} color="primary">Save</Button>
                <Button onClick={props.onCancel} color="default">Cancel</Button>
            </DialogActions>
        </Dialog>
    );
}

export default HistoryDialog;

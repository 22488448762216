import React, {useEffect, useState} from "react";
import {Projection} from "../../interfaces/projection.interface";
import {Dialog, DialogActions, InputLabel, MenuItem, Select} from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import ScheduleService, {Schedule} from "../../services/schedule.service";
import useAccountContext from "../../providers/AccountContextProvider";

interface ProjectionDialogProps {
    open: boolean,
    mode: 'ADD' | 'EDIT',
    onCancel: (() => void),
    onSave: ((projection: Projection) => void),
    projection: Projection
}

const ProjectionDialog = (props: ProjectionDialogProps) => {

    const {account} = useAccountContext();

    const [schedules, setSchedules] = useState<Schedule[]>([]);

    const [projectionId, setProjectionId] = useState(props.projection.projectionId);
    const [scheduleId, setScheduleId] = useState(props.projection.schedule?.scheduleId || 0);
    const [txnDate, setTxnDate] = useState(props.projection.txnDate);
    const [name, setName] = useState(props.projection.name);
    const [amount, setAmount] = useState(props.projection.amount);
    const [inputAmount, setInputAmount] = useState(props.projection.amount.toString());

    useEffect(() => {
        if (account.accountId) {
            ScheduleService.getAllForAccount(account.accountId)
                .then(response => setSchedules(response.data))
                .catch(e => console.error(e));
        }
    }, [account]);

    useEffect(() => {
        setProjectionId(props.projection.projectionId);
        setScheduleId(props.projection.schedule?.scheduleId || 0);
        setTxnDate(props.projection.txnDate);
        setName(props.projection.name);
        setAmount(props.projection.amount);
        setInputAmount(props.projection.amount.toString());
    }, [props.projection]);

    const onScheduleChange = (event: any) => {
        setScheduleId(event.target.value);
    }

    const onTxnDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTxnDate(event.target.value);
    }

    const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value);
    };

    const onAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInputAmount(event.target.value);

        const attemptedAmount = parseFloat(event.target.value);

        if (!Object.is(NaN, attemptedAmount)) {
            setAmount(parseFloat(event.target.value));
        }
    }

    const onSave = () => {
        let matchingSchedule = undefined;
        if (scheduleId !== 0) {
            matchingSchedule = schedules.find(s => s.scheduleId === scheduleId);
        }
        props.onSave({projectionId, account, schedule: matchingSchedule, txnDate, name, amount});
    }

    return (
        <Dialog open={props.open} onClose={props.onCancel} aria-labelledby={"form-dialog-title"}>
            <DialogTitle id="form-dialog-title">{`${props.mode === 'ADD' ? 'Add' : 'Edit'} Projection`}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Enter the details for the projection.
                </DialogContentText>
                <TextField margin="dense" id="projectionId" label="Projection Id" type="number" fullWidth
                           value={projectionId} disabled
                />
                <div>
                    <FormControl>
                        <InputLabel id="related-schedule-select-label">Related Schedule</InputLabel>
                        <Select labelId="related-schedule-select-label" id="relatedSchedule" value={scheduleId}
                                onChange={onScheduleChange} fullWidth>
                            <MenuItem value={0}>Ad Hoc (No Related Schedule)</MenuItem>
                            {schedules.map(schedule => (
                                <MenuItem key={schedule.scheduleId} value={schedule.scheduleId}>
                                    {schedule.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
                <TextField margin="dense" id="start" label="Projected Date" type="date" fullWidth
                           value={txnDate} onChange={onTxnDateChange} InputLabelProps={{ shrink: true }}/>
                <TextField margin="dense" id="name" label="Name" type="text" fullWidth
                           value={name} onChange={onNameChange}/>
                <TextField margin="dense" id="amount" label="Amount" type="text" fullWidth
                           value={inputAmount} onChange={onAmountChange}/>
            </DialogContent>
            <DialogActions>
                <Button onClick={onSave} color="primary">Save</Button>
                <Button onClick={props.onCancel} color="default">Cancel</Button>
            </DialogActions>
        </Dialog>
    )
}

export default ProjectionDialog;

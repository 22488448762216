import {AxiosResponse} from 'axios';
import {api} from "./utils";
import {Projection} from "../interfaces/projection.interface";
import {EmptyAccount} from "./account.service";

export const EmptyProjection: Projection = {
    projectionId: 0,
    account: EmptyAccount,
    txnDate: '',
    name: '',
    amount: 0
}

const getAll = (): Promise<AxiosResponse<Projection[]>> => api.get("/projection");

const getAllSoon = (): Promise<AxiosResponse<Projection[]>> => api.get("/projection?days=10");

const getAllForAccount = (accountId: number): Promise<AxiosResponse<Projection[]>> =>
    api.get(`/projection/account/${accountId}`);

const getSoftProjections = (accountId: number, fromDate: string): Promise<AxiosResponse<Projection[]>> =>
    api.get(`/projection/account/${accountId}/from/${fromDate}`);

const getNeededSoftProjections = (accountId: number): Promise<AxiosResponse<Projection[]>> =>
    api.get(`/projection/account/${accountId}/once`);

const get = (projectionId: number): Promise<AxiosResponse<Projection>> =>
    api.get(`/projection/${projectionId}`);

const add = (projection: Projection): Promise<AxiosResponse<Projection>> =>
    api.post("/projection", projection);

const update = (projectionId: number, projection: Projection): Promise<AxiosResponse<Projection>> =>
    api.put(`/projection/${projectionId}`, projection);

const del = (projectionId: number): Promise<AxiosResponse<Projection>> =>
    api.delete(`/projection/${projectionId}`);

const ProjectionService = {
    getAll,
    getAllSoon,
    getAllForAccount,
    getSoftProjections,
    getNeededSoftProjections,
    get,
    add,
    update,
    del
};

export default ProjectionService;

import React, {Component} from "react";
import {ScheduleType} from "../../services/schedule.service";
import {specialTypeLookup, weekTypeLookup} from "../schedule/lookups";

interface WhenCellRendererProps {
    value: any,
    data: any
}

class WhenCellRenderer extends Component<WhenCellRendererProps> {

    ordinal_suffix_of(i: number) {
        const j = i % 10,
              k = i % 100;
        if (j === 1 && k !== 11) {
            return i + "st";
        }
        if (j === 2 && k !== 12) {
            return i + "nd";
        }
        if (j === 3 && k !== 13) {
            return i + "rd";
        }
        return i + "th";
    }

    getWhenValue(scheduleType: ScheduleType, scheduleValue: number) {
        switch (scheduleType) {
            case "ONCE":
                return 'Once';
            case "MONTHLY":
                return 'Every month on the ' + this.ordinal_suffix_of(scheduleValue);
            case "WEEKLY":
                // @ts-ignore
                return 'Every week on ' + weekTypeLookup[scheduleValue];
            case "SPECIAL":
                // @ts-ignore
                return specialTypeLookup[scheduleValue];
            default:
                return '';
        }
    }

    render() {
        return (
            <span>
                {this.getWhenValue(this.props.data.scheduleType, this.props.data.scheduleValue)}
            </span>
        )
    }
}

export default WhenCellRenderer;

import * as React from "react";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import {monthTypeLookup, specialTypeLookup, weekTypeLookup} from "./lookups";
import {InputLabel} from "@material-ui/core";

class ScheduleValueEdit extends React.Component {
    renderLookupField() {
        const { scheduleType, error, value, onChange } = this.props;
        const lookupObject = scheduleType === 'MONTHLY' ? monthTypeLookup :
            scheduleType === 'WEEKLY' ? weekTypeLookup : specialTypeLookup;
        return (
            <>
                {scheduleType !== 'ONCE' && (
                    <FormControl error={Boolean(error)}>
                        <InputLabel id="schedule-value-edit-label">Value</InputLabel>
                        <Select
                            labelId="schedule-value-edit-label"
                            value={value === undefined ? "" : value}
                            onChange={onChange}
                            fullWidth
                        >
                            {Object.keys(lookupObject).map((key) => (
                                <MenuItem key={key} value={key}>
                                    {lookupObject[key]}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                )}
            </>
        );
    }

    render() {
        return this.renderLookupField();
    }
}

export default ScheduleValueEdit;

import React, {Fragment, useCallback, useEffect, useState} from 'react';
import {Box, Container} from "@material-ui/core";
import ProjectionService, {EmptyProjection} from '../../services/projection.service';
import {Projection} from "../../interfaces/projection.interface";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import {AgGridColumn, AgGridReact} from "ag-grid-react";
import BtnCellRenderer from "../grid/BtnCellRenderer";
import DateRenderer from "../grid/DateRenderer";
import {GridApi, GridReadyEvent} from "ag-grid-community";
import IconButton from "@material-ui/core/IconButton";
import {Add} from "@material-ui/icons";
import ProjectionDialog from "./ProjectionDialog";
import useAccountContext from "../../providers/AccountContextProvider";


const ProjectionComponent = () => {

    const {account} = useAccountContext();

    const [data, setData] = useState<Projection[]>([]);
    const [gridApi, setGridApi] = useState<GridApi>();
    const [modalProjection, setModalProjection] = useState<Projection>(EmptyProjection);
    const [mode, setMode] = useState<'ADD' | 'EDIT'>('ADD');
    const [open, setOpen] = useState(false);

    const loadProjections = useCallback(() => {
        if (account.accountId) {
            ProjectionService.getAllForAccount(account.accountId)
                .then(response => {
                    setData(response.data)
                })
                .catch(e => {
                    console.error(e);
                });
        }
    }, [account]);

    useEffect(() => {
        if (account) {
            loadProjections();
        }
    }, [account]);

    useEffect(() => {
        if (gridApi && data.length > 0) {
            gridApi.sizeColumnsToFit();
        }
    }, [gridApi, data]);

    const onGridReady = (params: GridReadyEvent) => {
        setGridApi(params.api);
    }

    const onAddRow = () => {
        setModalProjection({...EmptyProjection, account});
        setMode('ADD');
        setOpen(true);
    }

    const onEditRow = (rowData: Projection) => {
        setModalProjection(rowData);
        setMode('EDIT');
        setOpen(true);
    }

    const onDeleteRow = (rowData: Projection) => {
        ProjectionService.del(rowData.projectionId)
            .then(() => loadProjections())
            .catch(e => console.error(e));
    }

    const handleCancel = () => {
        setOpen(false);
    }

    const handleSave = (projectionToSave: Projection) => {
        if (mode === 'ADD') {
            ProjectionService.add(projectionToSave)
                .then(() => loadProjections())
                .catch(e => console.error(e));
        } else {
            ProjectionService.update(projectionToSave.projectionId, projectionToSave)
                .then(() => loadProjections())
                .catch(e => console.error(e));
        }
        setOpen(false);
    }

    return (
        <Fragment>
            {modalProjection && (
                <ProjectionDialog open={open} mode={mode} onCancel={handleCancel} onSave={handleSave}
                                  projection={modalProjection}/>
            )}
            <Container maxWidth="xl">
                <Typography variant="h4">Account Projections</Typography>
                {account.accountId && (
                    <Box component={Paper} className="ag-theme-alpine-dark" style={{width: "100%"}}>
                        <div style={{display: 'flex', flexDirection: 'row'}}>
                            <div style={{overflow: 'hidden', flexGrow: 1}}>
                                <AgGridReact rowData={data} domLayout={"autoHeight"} onGridReady={onGridReady}
                                             frameworkComponents={{
                                                 btnCellRenderer: BtnCellRenderer, dateRenderer: DateRenderer
                                             }}>
                                    <AgGridColumn field="projectionId" />
                                    <AgGridColumn field="schedule.name" sortable={true} filter={true}/>
                                    <AgGridColumn field="txnDate" headerName="Date" sortable={true} filter={true}
                                                  cellRenderer={'dateRenderer'}/>
                                    <AgGridColumn field="name" sortable={true} filter={true}/>
                                    <AgGridColumn field="amount" sortable={true}/>
                                    <AgGridColumn field="projectionId" maxWidth={120} headerName="Actions"
                                                  cellRenderer={'btnCellRenderer'} cellRendererParams={{
                                                      onEdit: onEditRow,
                                                      onDelete: onDeleteRow
                                                  }}/>
                                </AgGridReact>
                            </div>
                        </div>
                        <IconButton onClick={onAddRow} component="span" color="primary">
                            <Add/>
                        </IconButton>
                    </Box>
                )}
            </Container>
        </Fragment>
    )
};

export default ProjectionComponent;

import {AxiosResponse} from 'axios';
import {api} from "./utils";
import {Account} from "./account.service";


export type History = {
    historyId: number,
    account: Account,
    sequence: number,
    txnDate: string,
    name: string,
    amount: number,
    balance: number
}

const getAll = (): Promise<AxiosResponse<History[]>> => api.get("/history");

const getAllForAccount = (accountId: number): Promise<AxiosResponse<History[]>> =>
    api.get(`/history/account/${accountId}`);

const getRecentForAccount = (accountId: number): Promise<AxiosResponse<History[]>> =>
    api.get(`/history/account/${accountId}/recent`);

const get = (historyId: number): Promise<AxiosResponse<History>> =>
    api.get(`/history/${historyId}`);

const add = (history: History): Promise<AxiosResponse<History>> =>
    api.post("/history", history);

const update = (historyId: number, history: History): Promise<AxiosResponse<History>> =>
    api.put(`/history/${historyId}`, history);

const del = (historyId: number): Promise<AxiosResponse<History>> =>
    api.delete(`/history/${historyId}`);

const HistoryService =  {
    getAll,
    getAllForAccount,
    getRecentForAccount,
    get,
    add,
    update,
    del
};

export default HistoryService;

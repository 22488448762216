import React, {Component} from "react";

interface CheckboxRendererProps {
    value: boolean
}

class CheckboxRenderer extends Component<CheckboxRendererProps> {

    render() {
        return (
            <input type="checkbox" checked={this.props.value} readOnly />
        );
    }
}

export default CheckboxRenderer;

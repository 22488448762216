import axios from 'axios';
import AuthService from '../services/auth.service';

const authUser = AuthService.getCurrentUser();

export const api = axios.create({
    baseURL: '/api',
    headers: {
        "Content-type": "application/json",
        "Authorization": "Bearer " + authUser?.token
    }
});

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Link as RouterLink} from "react-router-dom";
import {Box} from "@material-ui/core";
import AuthService from '../services/auth.service';
import AccountContext from "./account-context";
import useAccountContext from "../providers/AccountContextProvider";

const useStyles = makeStyles((theme) => ({
    logo: {
        width: theme.spacing(4),
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
    link: {
        textDecoration: 'none',
    },
}));

const TopBar = (props) => {
    const classes = useStyles();

    const { account } = useAccountContext();
    const [currentUser, setCurrentUser] = useState(null);

    useEffect(() => {
        setCurrentUser(AuthService.getCurrentUser());
    }, []);

    function handleLogout() {
        AuthService.logout();
        if (props.history) {
            props.history.push("/");
        } else {
            setCurrentUser(null);
        }
        window.location.reload();
    }

    console.log(account);

    return (
        <AppBar position="static">
            <Toolbar>
                <img className={classes.logo} src="/monkeybutt.png" alt="DeLuded Monkey Butt Logo" />
                <Box className={classes.title}>
                    <Typography variant="h6" component={RouterLink} to="/" color="inherit" className={classes.link}>
                        DeLuded Forecasting
                        <AccountContext />
                    </Typography>
                </Box>
                {currentUser ? (
                    <>
                        <Button component={RouterLink} to={account.virtual ? "/virt-forecast" : "/forecast"} color="inherit" size="large">Forecast</Button>
                        <Button component={RouterLink} to="/accounts" color="inherit" size="large">Accounts</Button>
                        <Button component={RouterLink} to="/schedule" color="inherit" size="large" disabled={account.virtual}>Schedule</Button>
                        <Button component={RouterLink} to="/history" color="inherit" size="large" disabled={account.virtual}>History</Button>
                        <Button component={RouterLink} to="/projection" color="inherit" size="large" disabled={account.virtual}>Projections</Button>
                        <Button component={RouterLink} to="/profile" color="inherit" size="large">Profile</Button>
                        <Button color="inherit" size="large" onClick={handleLogout}>Logout</Button>
                    </>
                ) : (
                    <>
                        <Button component={RouterLink} to="/signin" color="inherit" size="large">Sign In</Button>
                        <Button component={RouterLink} to="/signup" color="inherit" size="large">Sign Up</Button>
                    </>
                )}
            </Toolbar>
        </AppBar>
    )
}

export default TopBar;

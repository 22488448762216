import React from "react";
import {Box} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import {Chart} from "react-google-charts";

interface ForecastGraphProps {
    balances: any[],
    styles: Record<'section' | 'tableTitle', string>
}

const ForecastGraph = (props: ForecastGraphProps) => {

    const {balances, styles} = props;

    return (
        <Box component={Paper} className={styles.section}>
        <Chart
            width={'100%'}
            height={200}
            chartType="LineChart"
            loader={<div>Loading Chart</div>}
            data={[
                [
                    {type: 'date', label: 'date'},
                    {type: 'number', label: 'balance'}
                ],
                ...balances
            ]}
            options={{
                intervals: {style: 'sticks'},
                legend: 'none',
                backgroundColor: '#133062',
                vAxis: {
                    textStyle: {color: 'white'},
                    gridlines: {color: '#787878'},
                    titleTextStyle: {color: 'white'},
                    baseline: 0,
                    baselineColor: 'white'
                },
                hAxis: {
                    textStyle: {color: 'white'},
                    gridlines: {color: '#787878'},
                },
                colors: ['#21E6C1']
            }}
        />
    </Box>
    );
}

export default ForecastGraph;

import React from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import {makeStyles, ThemeProvider} from '@material-ui/core/styles';
import Routes from "./components/Routes";
import theme from "./theme";
import CssBaseline from "@material-ui/core/CssBaseline";
import {HashRouter} from "react-router-dom";
import TopBar from "./components/TopBar";
import {Box} from "@material-ui/core";
import { AccountContextProvider } from "./providers/AccountContextProvider";


function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © Bill DeLude '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
    },
    main: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(2),
    },
    footer: {
        padding: theme.spacing(1, 2),
        marginTop: 'auto',
        backgroundColor: theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[800],
    },
    version: {
        float: "right",
        color: theme.palette.grey[500]
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
}));

export default function App() {
    const classes = useStyles();

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <HashRouter>
                <div className={classes.root}>
                    <AccountContextProvider>
                        <TopBar />
                        <Container component="main" className={classes.main} maxWidth="xl">
                            <Routes/>
                        </Container>
                        <footer className={classes.footer}>
                            <Box className={classes.version}>
                                {`${process.env.REACT_APP_NAME} ${process.env.REACT_APP_VERSION}`}
                            </Box>
                            <Container maxWidth="sm">
                                <Copyright />
                            </Container>
                        </footer>
                    </AccountContextProvider>
                </div>
            </HashRouter>
        </ThemeProvider>
    );
}

import {createMuiTheme, createStyles} from "@material-ui/core/styles";
import withStyles from "@material-ui/core/styles/withStyles";
import TableRow from "@material-ui/core/TableRow";

const theme = createMuiTheme({
    palette: {
        type: "dark",
        primary: {
            main: '#21E6C1',
        },
        secondary: {
            main: "#278EA5"
        },
        background: {
            default: "#071E3D"
        }
    }
});

export const StyledTableRow = withStyles((theme) =>
    createStyles({
        root: {
            '&:nth-of-type(even)': {
                backgroundColor: theme.palette.type === 'light' ? '#ecf2f9' : '#133062',
            },
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.type === 'light' ? '#ecf2f9' : '#1F4287',
            },
        },
    }),
)(TableRow);

export default theme;

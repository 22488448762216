import React, {Fragment, useCallback, useEffect, useState} from 'react';
import {Box, Container} from "@material-ui/core";
import HistoryService, {History} from '../../services/history.service';
import {GridApi, GridReadyEvent} from "ag-grid-community";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import {AgGridColumn, AgGridReact} from "ag-grid-react";
import BtnCellRenderer from "../grid/BtnCellRenderer";
import IconButton from "@material-ui/core/IconButton";
import Add from "@material-ui/icons/Add";
import HistoryDialog from "./HistoryDialog";
import DateRenderer from "../grid/DateRenderer";
import useAccountContext from "../../providers/AccountContextProvider";

const newHistory: History = {
    historyId: 0,
    account: {accountId: 0, name: ''},
    sequence: 0,
    txnDate: '',
    name: '',
    amount: 0,
    balance: 0
}

const HistoryComponent = () => {

    const {account} = useAccountContext();

    const [data, setData] = useState<History[]>([]);
    const [gridApi, setGridApi] = useState<GridApi | null>(null);
    const [mode, setMode] = useState<'ADD' | 'EDIT'>('ADD');
    const [open, setOpen] = useState(false);
    const [modalHistory, setModalHistory] = useState<History>(newHistory);

    const loadHistory = useCallback(() => {
        if (account.accountId) {
            HistoryService.getAllForAccount(account.accountId)
                .then(response => {
                    setData(response.data)
                })
                .catch(e => {
                    console.error(e);
                });
        }
    }, [account]);

    useEffect(() => {
        loadHistory();
    }, [loadHistory]);

    useEffect(() => {
        if (gridApi && data.length > 0) {
            gridApi.sizeColumnsToFit();
        }
    }, [gridApi, data]);

    const onGridReady = (params: GridReadyEvent) => {
        setGridApi(params.api);
    };

    const onAddRow = () => {
        setModalHistory({...newHistory, account});
        setMode('ADD');
        setOpen(true);
    }

    const onEditRow = (rowData: History) => {
        setModalHistory(rowData);
        setMode('EDIT');
        setOpen(true);
    }

    const onDeleteRow = (rowData: History) => {
        HistoryService.del(rowData.historyId)
            .then(() => {
                const filtered = data.filter(h => h.historyId !== rowData.historyId);
                setData([...filtered]);
                loadHistory();
            })
            .catch(e => {
                console.error(e);
            })
    }

    const handleCancel = () => {
        setOpen(false);
    }

    const handleSave = (historyToSave: History) => {
        if (mode === 'ADD') {
            HistoryService.add(historyToSave)
                .then(response => {
                    setData([...data, response.data]);
                    loadHistory();
                })
                .catch(e => {
                    console.error(e);
                })
        } else {
            HistoryService.update(historyToSave.historyId, historyToSave)
                .then(() => loadHistory())
                .catch(e => console.error(e))
        }
        setOpen(false);
    }

    return (
        <Fragment>
            {modalHistory && (
                <HistoryDialog open={open} mode={mode} onCancel={handleCancel} onSave={handleSave}
                               history={modalHistory} />
            )}
            <Container maxWidth="xl">
                <Typography variant="h4">Account History</Typography>
                {account.accountId && (
                    <Box component={Paper} className="ag-theme-alpine-dark" style={{width: "100%"}}>
                        <div style={{display: 'flex', flexDirection: 'row'}}>
                            <div style={{overflow: 'hidden', flexGrow: 1}}>
                                <AgGridReact rowData={data} domLayout={"autoHeight"} onGridReady={onGridReady}
                                             frameworkComponents={{
                                                 btnCellRenderer: BtnCellRenderer,
                                                 dateRenderer: DateRenderer
                                             }}>
                                    <AgGridColumn field="historyId" filter={true}/>
                                    <AgGridColumn field="sequence" sortable={true} filter={true}/>
                                    <AgGridColumn field="txnDate"
                                                  sortable={true}
                                                  filter={true}
                                                  headerName="Transaction Date"
                                                  cellRenderer={'dateRenderer'}/>
                                    <AgGridColumn field="name" sortable={true} filter={true}/>
                                    <AgGridColumn field="amount"/>
                                    <AgGridColumn field="balance"/>
                                    <AgGridColumn field="historyId"
                                                  maxWidth={120}
                                                  headerName="Actions"
                                                  cellRenderer={'btnCellRenderer'}
                                                  cellRendererParams={{
                                                      onEdit: onEditRow,
                                                      onDelete: onDeleteRow
                                                  }}/>
                                </AgGridReact>
                            </div>
                        </div>
                        <IconButton onClick={onAddRow} component="span" color="primary">
                            <Add/>
                        </IconButton>
                    </Box>
                )}
            </Container>
        </Fragment>
    )
}

export default HistoryComponent;

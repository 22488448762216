import React, {useEffect, useState} from 'react';
import { Account } from "../../services/account.service";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {Checkbox, FormControlLabel, InputLabel, MenuItem, Select} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";

interface AccountDialogProps {
    modalOpen: boolean,
    mode: 'ADD' | 'EDIT',
    onCancel: (() => void),
    onSave: ((account: Account) => void),
    account: Account,
    accounts: Account[]
}

const AccountDialog = (props: AccountDialogProps) => {

    const [accountId, setAccountId] = useState(props.account.accountId);
    const [name, setName] = useState(props.account.name);
    const [minimumBalance, setMinimumBalance] = useState(props.account.minimumBalance);
    const [inputBalance, setInputBalance] = useState(
        props.account.minimumBalance ? props.account.minimumBalance.toString() : '');
    const [virtual, setVirtual] = useState(props.account.virtual);
    const [retired, setRetired] = useState(props.account.retired);
    const [parentAccount, setParentAccount] = useState(props.account.parentAccount);

    useEffect(() => {
        setAccountId(props.account.accountId);
        setName(props.account.name);
        setMinimumBalance(props.account.minimumBalance);
        setInputBalance(props.account.minimumBalance ? props.account.minimumBalance.toString() : '');
        setVirtual(props.account.virtual);
        setRetired(props.account.retired);
        setParentAccount(props.account.parentAccount);
    }, [props.account]);

    const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const name = event.target.value;
        setName(name);
    };

    const onBalanceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInputBalance(event.target.value);

        const attemptedBalance = parseFloat(event.target.value);

        if (!Object.is(NaN, attemptedBalance)) {
            setMinimumBalance(parseFloat(event.target.value));
        }
    }

    const onVirtualChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setVirtual(event.target.checked);
    }

    const onRetiredChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRetired(event.target.checked);
    }

    const onParentAccountChange = (event: any) => {
        const targetAccountId = parseInt(event.target.value);
        const foundParentAccount = props.accounts.find(a => a.accountId === targetAccountId);
        setParentAccount(foundParentAccount);
    }

    return (
        <Dialog open={props.modalOpen} onClose={props.onCancel} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{`${props.mode === 'ADD' ? 'Add' : 'Edit'} Account`}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Enter the details for the account.
                </DialogContentText>
                <TextField margin="dense" id="accountId" label="Account Id" type="number" fullWidth
                           value={accountId} disabled/>
                <TextField autoFocus margin="dense" id="name" label="Name" type="text" fullWidth
                           value={name} onChange={onNameChange} />
                <TextField margin="dense" id="balance" label="Minimum Balance" type="text" fullWidth
                           value={inputBalance} onChange={onBalanceChange}
                />
                <FormControlLabel control={
                    <Checkbox checked={virtual} onChange={onVirtualChange} name="virtual" color="primary" />
                } label="Virtual Account"
                />
                <FormControlLabel control={
                    <Checkbox checked={retired} onChange={onRetiredChange} name="retired" color="primary" />
                } label="Retired"
                />
                <div>
                    <FormControl>
                        <InputLabel id="parent-account-select-label">Parent Account</InputLabel>
                        <Select labelId="parent-account-select-label" id="parentAccount"
                                value={parentAccount ? parentAccount.accountId : 0}
                                onChange={onParentAccountChange} fullWidth>
                            <MenuItem value={0}>None</MenuItem>
                            {props.accounts.filter(fa => fa.virtual).map(a => (
                                <MenuItem key={a.accountId} value={a.accountId}>
                                    {a.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.onSave({
                    accountId, name, minimumBalance, virtual, parentAccount, retired
                })} color="primary">Save</Button>
                <Button onClick={props.onCancel} color="default">Cancel</Button>
            </DialogActions>
        </Dialog>
    );
}

export default AccountDialog;

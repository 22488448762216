import React, {useEffect, useState} from 'react';
import {Schedule} from '../../services/schedule.service';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import ScheduleValueEdit from "./ScheduleValueEdit";
import {InputLabel, MenuItem, Select} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";

interface ScheduleDialogProps {
    open: boolean,
    mode: 'ADD' | 'EDIT',
    onCancel: (() => void),
    onSave: ((schedule: Schedule) => void),
    schedule: Schedule
}

const ScheduleDialog = (props: ScheduleDialogProps) => {

    const [account, setAccount] = useState(props.schedule.account);
    const [scheduleId, setScheduleId] = useState(props.schedule.scheduleId);
    const [name, setName] = useState(props.schedule.name);
    const [amount, setAmount] = useState(props.schedule.amount);
    const [inputAmount, setInputAmount] = useState(props.schedule.amount.toString());
    const [scheduleType, setScheduleType] = useState(props.schedule.scheduleType);
    const [scheduleValue, setScheduleValue] = useState(props.schedule.scheduleValue);
    const [start, setStart] = useState(props.schedule.start);
    const [end, setEnd] = useState(props.schedule.end);

    useEffect(() => {
        setAccount(props.schedule.account);
        setScheduleId(props.schedule.scheduleId);
        setName(props.schedule.name);
        setAmount(props.schedule.amount);
        setInputAmount(props.schedule.amount.toString());
        setScheduleType(props.schedule.scheduleType);
        setScheduleValue(props.schedule.scheduleValue);
        setStart(props.schedule.start);
        setEnd(props.schedule.end);
    }, [props.schedule]);

    const onNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value);
    };

    const onAmountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInputAmount(event.target.value);

        const attemptedAmount = parseFloat(event.target.value);

        if (!Object.is(NaN, attemptedAmount)) {
            setAmount(parseFloat(event.target.value));
        }
    }

    const onScheduleTypeChange = (event: any) => {
        setScheduleType(event.target.value);
    }

    const onScheduleValueChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setScheduleValue(parseInt(event.target.value));
    }

    const onStartChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setStart(event.target.value);
    }

    const onEndChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEnd(event.target.value);
    }

    return (
        <Dialog open={props.open} onClose={props.onCancel} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{`${props.mode === 'ADD' ? 'Add' : 'Edit'} Schedule`}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Enter the details for the schedule.
                </DialogContentText>
                <TextField margin="dense" id="scheduleId" label="Schedule Id" type="number" fullWidth
                           value={scheduleId} disabled/>
                <TextField autoFocus margin="dense" id="name" label="Name" type="text" fullWidth
                           value={name} onChange={onNameChange}/>
                <TextField margin="dense" id="amount" label="Amount" type="text" fullWidth
                           value={inputAmount} onChange={onAmountChange}/>
                <div>
                    <FormControl>
                        <InputLabel id="schedule-type-select-label">Type</InputLabel>
                        <Select labelId="schedule-type-select-label" id="scheduleType" value={scheduleType}
                                onChange={onScheduleTypeChange}>
                            <MenuItem value={'ONCE'}>Once</MenuItem>
                            <MenuItem value={'MONTHLY'}>Monthly</MenuItem>
                            <MenuItem value={'WEEKLY'}>Weekly</MenuItem>
                            <MenuItem value={'SPECIAL'}>Special</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                <ScheduleValueEdit scheduleType={scheduleType} value={scheduleValue} onChange={onScheduleValueChange}/>
                <TextField margin="dense" id="start" label="Start Date" type="date" fullWidth
                           value={start} onChange={onStartChange} InputLabelProps={{ shrink: true }}/>
                <TextField margin="dense" id="end" label="End Date" type="date" fullWidth
                           value={end} onChange={onEndChange} InputLabelProps={{ shrink: true }}/>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => props.onSave({
                    scheduleId, account, name, amount, scheduleType, scheduleValue, start, end
                })} color="primary">Save</Button>
                <Button onClick={props.onCancel} color="default">Cancel</Button>
            </DialogActions>
        </Dialog>
    );

}

export default ScheduleDialog;

import {AxiosResponse} from 'axios';
import {api} from "./utils";
import {Account} from "./account.service";

export type ScheduleType = 'ONCE' | 'WEEKLY' | 'MONTHLY' | 'SPECIAL';

export type Schedule = {
    scheduleId: number,
    account: Account,
    name: string,
    amount: number,
    scheduleType: ScheduleType,
    scheduleValue: number,
    start: string,
    end?: string
}

export const EmptySchedule: Schedule = {
    scheduleId: 0,
    account: {accountId: 0, name: ''},
    name: '',
    amount: 0,
    scheduleType: "MONTHLY",
    scheduleValue: 1,
    start: ''
}

const getAll = (): Promise<AxiosResponse<Schedule[]>> => api.get("/schedule");

const getAllForAccount = (accountId: number): Promise<AxiosResponse<Schedule[]>> =>
    api.get(`/schedule/account/${accountId}`);

const get = (scheduleId: number): Promise<AxiosResponse<Schedule>> =>
    api.get(`/schedule/${scheduleId}`);

const add = (schedule: Schedule): Promise<AxiosResponse<Schedule>> =>
    api.post("/schedule", schedule);

const update = (scheduleId: number, schedule: Schedule): Promise<AxiosResponse<Schedule>> =>
    api.put(`/schedule/${scheduleId}`, schedule);

const del = (scheduleId: number): Promise<AxiosResponse<Schedule>> =>
    api.delete(`/schedule/${scheduleId}`);

const ScheduleService = {
    getAll,
    getAllForAccount,
    get,
    add,
    update,
    del
};

export default ScheduleService;

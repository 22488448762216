import React, {useContext, useState} from "react";
import {Account, EmptyAccount} from "../services/account.service";

type AccountContextType = {
    account: Account,
    setAccount: (account: Account) => void
}

const initialState: AccountContextType = {
    account: EmptyAccount,
    setAccount: () => {}
}

export const AccountContext = React.createContext<AccountContextType>(initialState);

export const AccountContextProvider: React.FC = props => {

    const [account, setAccount] = useState<Account>(EmptyAccount);

    return (
        <AccountContext.Provider value={{ account, setAccount }}>
            {props.children}
        </AccountContext.Provider>
    );
};

const useAccountContext = () => useContext(AccountContext);
export default useAccountContext;

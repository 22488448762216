import React, {Fragment, useCallback, useEffect, useState} from "react";
import Container from "@material-ui/core/Container";
import HistoryService, {History} from "../../services/history.service";
import ProjectionService, {EmptyProjection} from "../../services/projection.service";
import {makeStyles} from "@material-ui/core/styles";
import {Box, CircularProgress} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import TableBody from "@material-ui/core/TableBody";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import SaveIcon from "@material-ui/icons/Save";
import AddIcon from "@material-ui/icons/Add";
import { currencyFormat } from "../../utils";
import Button from "@material-ui/core/Button";
import {LocalDate} from "@js-joda/core";
import {Projection} from "../../interfaces/projection.interface";
import ProjectionDialog from "../projections/ProjectionDialog";
import ForecastGraph from "./ForecastGraph";
import { StyledTableRow } from "../../theme";
import HistoryTable from "./HistoryTable";
import SoftProjections from "./SoftProjections";
import useAccountContext from "../../providers/AccountContextProvider";

export interface ForecastProjection extends Projection {
    balance: number,
    source: string
}

const useStyles = makeStyles((theme) => ({
    section: {
        marginBottom: theme.spacing() * 2,
        backgroundColor: '#133062'
    },
    tableTitle: {
        fontSize: "x-large",
        fontWeight: 700,
        margin: theme.spacing() * 3
    },
    addButton: {
        float: "right",
        marginTop: theme.spacing() * 3,
        marginRight: theme.spacing() * 3
    },
    negativeBalance: {
        color: theme.palette.type === 'light' ? "red" : 'tomato'
    },
    positiveBalance: {
        color: "inherit"
    },
    warningBalance: {
        color: theme.palette.type === 'light' ? "darkgoldenrod" : "yellow"
    },
    trashCan: {
        color: theme.palette.type === 'light' ? "red" : 'tomato'
    }
}));

const projectionCompare = (a: Projection, b: Projection) => {
    // Sort first by txnDate ascending, then amount descending
    return a.txnDate.localeCompare(b.txnDate) || b.amount - a.amount;
}

const Forecast = () => {
    const styles = useStyles();

    const {account} = useAccountContext();

    const [historyData, setHistoryData] = useState<History[]>([]);
    const [projData, setProjData] = useState<Projection[]>([]);
    const [softProjData, setSoftProjData] = useState<Projection[]>([]);
    const [hardProjections, setHardProjections] = useState<ForecastProjection[]>([]);
    const [softProjections, setSoftProjections] = useState<ForecastProjection[]>([]);
    const [addEditModalOpen, setAddEditModalOpen] = useState(false);
    const [modalMode, setModalMode] = useState<'ADD' | 'EDIT'>('ADD');
    const [modalProjection, setModalProjection] = useState<Projection>({...EmptyProjection, txnDate: LocalDate.now().toString(), account});
    const [balances, setBalances] = useState<any[]>([]);
    const [isLoadingHistory, setIsLoadingHistory] = useState(false);
    const [isLoadingProjections, setIsLoadingProjections] = useState(false);

    const loadHistory = useCallback(() => {
        if (account.accountId) {
            setIsLoadingHistory(true);
            HistoryService.getRecentForAccount(account.accountId)
                .then(response => {
                    setHistoryData(response.data.sort((a, b) => {
                        return a.sequence - b.sequence
                    }));
                    setIsLoadingHistory(false);
                })
                .catch(e => {
                    console.error(e);
                });
        }
    }, [account]);

    const loadProjections = useCallback(() => {
        if (account.accountId) {
            setIsLoadingProjections(true);
            ProjectionService.getAllForAccount(account.accountId)
                .then(response => {
                    setProjData(response.data.sort(projectionCompare));
                    setIsLoadingProjections(false);
                })
                .catch(e => {
                    console.error(e);
                })
        }
    }, [account]);

    const checkForNeededSoftProjections = useCallback(() => {
        if (account.accountId) {
            ProjectionService.getNeededSoftProjections(account.accountId)
                .then(response => {
                    const newSoftProjections: Projection[] = response.data.sort(projectionCompare);
                    setSoftProjData(newSoftProjections);
                })
                .catch(e => console.error(e));
        }
    }, [account]);

    useEffect(() => {
        if (account) {
            loadHistory();
            loadProjections();
            checkForNeededSoftProjections();
        }
    }, [account, loadHistory, loadProjections, checkForNeededSoftProjections])

    useEffect(() => {
        if (projData.length && historyData.length) {
            const newBalances = new Map();
            historyData.forEach(hd => {
                newBalances.set(hd.txnDate + "T00:00:00", hd.balance);
            });
            let lastBalance = historyData.slice(-1)[0].balance;
            setHardProjections(projData.map(pd => {
                lastBalance += pd.amount;
                newBalances.set(pd.txnDate + "T00:00:00", lastBalance);
                return {...pd, balance: lastBalance, source: pd.schedule ? 'SCHEDULED' : 'AD-HOC'};
            }))
            setSoftProjections(softProjData.map(pd => {
                lastBalance += pd.amount;
                newBalances.set(pd.txnDate + "T00:00:00", lastBalance);
                return {...pd, balance: lastBalance, source: 'SCHEDULED'};
            }))
            const balancesArray = [];
            // @ts-ignore
            for (let [key, value] of newBalances.entries()) {
                balancesArray.push([new Date(key), value]);
            }
            setBalances(balancesArray);
        }
    }, [projData, historyData, softProjData])

    const getSoftProjections = () => {
        let fromDate = hardProjections.slice(-1)[0].txnDate;
        if (softProjData.length) {
            fromDate = softProjData.slice(-1)[0].txnDate;
        }
        ProjectionService.getSoftProjections(account.accountId, fromDate)
            .then(response => {
                const newSoftProjections: Projection[] = response.data.sort(projectionCompare);
                setSoftProjData([...softProjData, ...newSoftProjections]);
            })
            .catch(e => console.error(e));
    }

    const onAddNewProjection = () => {
        setModalMode('ADD');
        setModalProjection({...EmptyProjection, txnDate: LocalDate.now().toString(), account});
        setAddEditModalOpen(true);
    }

    const onEditProjection = (rowData: ForecastProjection): void => {
        setModalProjection(rowData);
        setModalMode('EDIT');
        setAddEditModalOpen(true);
    }

    const onDeleteProjection = (rowData: ForecastProjection): void => {
        ProjectionService.del(rowData.projectionId)
            .then(() => loadProjections())
            .catch((e) => console.error(e));
    }

    const onCommitProjection = (projToCommit: ForecastProjection): void => {
        setIsLoadingHistory(true);
        setIsLoadingProjections(true);
        const lastHistory = historyData.slice(-1)[0];
        const newHistory: History = {
            historyId: 0,
            account,
            sequence: lastHistory.sequence + 1,
            txnDate: projToCommit.txnDate.toString(),
            name: projToCommit.name,
            amount: projToCommit.amount,
            balance: lastHistory.balance + projToCommit.amount
        };
        HistoryService.add(newHistory)
            .then(() => loadHistory())
            .catch(e => console.error(e));
        // Delay the loadProjections call to avoid rendering collisions
        ProjectionService.del(projToCommit.projectionId)
            .then(() => setTimeout(loadProjections, 100))
            .catch((e) => console.error(e));
    }

    const handleCancel = () => {
        setAddEditModalOpen(false);
    }

    const handleSave = (projectionToSave: Projection) => {
        setAddEditModalOpen(false);
        if (modalMode === 'ADD') {
            ProjectionService.add(projectionToSave)
                .then(() => {
                    loadProjections();
                })
                .catch((e) => console.error(e));
        } else {
            ProjectionService.update(projectionToSave.projectionId, projectionToSave)
                .then(() => {
                    loadProjections();
                })
                .catch((e) => console.error(e));
        }
    }

    function getBalanceStyle(row: ForecastProjection) {
        if (row.balance < 0) {
            return styles.negativeBalance;
        }
        if (account.minimumBalance && row.balance < account.minimumBalance) {
            return styles.warningBalance;
        }
        return styles.positiveBalance;
    }

    function getCommitButtonOrSpinner(row: ForecastProjection) {
        if (isLoadingHistory || isLoadingProjections) {
            return <CircularProgress size={30} />
        }
        return (
            <IconButton size="small" onClick={() => onCommitProjection(row)}
                        title="Commit Projection to History" color="default">
                <SaveIcon/>
            </IconButton>
        );
    }

    return (
        <Fragment>
            <ProjectionDialog open={addEditModalOpen} mode={modalMode} onCancel={handleCancel}
                              onSave={handleSave} projection={modalProjection} />
            <>
                {account.accountId && historyData.length && projData.length && (
                    <Container maxWidth="xl">
                        <ForecastGraph balances={balances} styles={styles} />
                        <HistoryTable historyData={historyData} styles={styles} />
                        <TableContainer component={Paper} className={styles.section}>
                            <IconButton onClick={onAddNewProjection} className={styles.addButton}
                                        title="Add New Projection">
                                <AddIcon fontSize="large"/>
                            </IconButton>
                            <Typography component="h2" className={styles.tableTitle}>3 Months of
                                Projections</Typography>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Date (YYYY-MM-DD)</TableCell>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Source</TableCell>
                                        <TableCell align="right">Amount</TableCell>
                                        <TableCell align="right">Balance</TableCell>
                                        <TableCell/>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {hardProjections.map((row, index) => (
                                        <StyledTableRow key={row.projectionId}>
                                            <TableCell>{row.txnDate}</TableCell>
                                            <TableCell>{row.name}</TableCell>
                                            <TableCell>{row.source}</TableCell>
                                            <TableCell align="right">{currencyFormat(row.amount)}</TableCell>
                                            <TableCell align="right" className={getBalanceStyle(row)}>
                                                {currencyFormat(row.balance)}
                                            </TableCell>
                                            <TableCell style={{width: '125px'}}>
                                                <IconButton size="small" onClick={() => onEditProjection(row)}
                                                            title="Edit Projection" color="primary">
                                                    <EditIcon/>
                                                </IconButton>
                                                <IconButton size="small" onClick={() => onDeleteProjection(row)}
                                                            title="Delete Projection" className={styles.trashCan}>
                                                    <DeleteIcon/>
                                                </IconButton>
                                                {index === 0 && getCommitButtonOrSpinner(row)}
                                            </TableCell>
                                        </StyledTableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <SoftProjections softProjections={softProjections} styles={styles} getBalanceStyle={getBalanceStyle} />
                        <Box>
                            <Button variant="contained" onClick={getSoftProjections}>See more projections</Button>
                        </Box>
                    </Container>
                )}
            </>
        </Fragment>
    )
}

export default Forecast;

import React, {Fragment, useCallback, useEffect, useState} from "react";
import Container from "@material-ui/core/Container";
import HistoryService, {History} from "../../services/history.service";
import ProjectionService from "../../services/projection.service";
import {makeStyles} from "@material-ui/core/styles";
import {Box} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import TableBody from "@material-ui/core/TableBody";
import Typography from "@material-ui/core/Typography";
import { currencyFormat } from "../../utils";
import Button from "@material-ui/core/Button";
import {Projection} from "../../interfaces/projection.interface";
import ForecastGraph from "./ForecastGraph";
import { StyledTableRow } from "../../theme";
import HistoryTable from "./HistoryTable";
import SoftProjections from "./SoftProjections";
import useAccountContext from "../../providers/AccountContextProvider";

export interface ForecastProjection extends Projection {
    balance: number,
    source: string
}

const useStyles = makeStyles((theme) => ({
    section: {
        marginBottom: theme.spacing() * 2,
        backgroundColor: '#133062'
    },
    tableTitle: {
        fontSize: "x-large",
        fontWeight: 700,
        margin: theme.spacing() * 3
    },
    negativeBalance: {
        color: "red"
    },
    positiveBalance: {
        color: "inherit"
    },
    warningBalance: {
        color: "darkgoldenrod"
    }
}));

const projectionCompare = (a: Projection, b: Projection) => {
    // Sort first by txnDate ascending, then amount descending
    return a.txnDate.localeCompare(b.txnDate) || b.amount - a.amount;
}

const VirtualForecast = () => {
    const styles = useStyles();

    const {account} = useAccountContext();

    const [historyData, setHistoryData] = useState<History[]>([]);
    const [projData, setProjData] = useState<Projection[]>([]);
    const [softProjData, setSoftProjData] = useState<Projection[]>([]);
    const [hardProjections, setHardProjections] = useState<ForecastProjection[]>([]);
    const [softProjections, setSoftProjections] = useState<ForecastProjection[]>([]);
    const [balances, setBalances] = useState<any[]>([]);

    const loadHistory = useCallback(() => {
        if (account.accountId) {
            HistoryService.getRecentForAccount(account.accountId)
                .then(response => {
                    setHistoryData(response.data);
                })
                .catch(e => {
                    console.error(e);
                });
        }
    }, [account]);

    const loadProjections = useCallback(() => {
        if (account.accountId) {
            ProjectionService.getAllForAccount(account.accountId)
                .then(response => {
                    setProjData(response.data.sort(projectionCompare));
                })
                .catch(e => {
                    console.error(e);
                })
        }
    }, [account]);

    useEffect(() => {
        if (account) {
            loadHistory();
            loadProjections();
        }
    }, [account, loadHistory, loadProjections])

    useEffect(() => {
        if (projData.length && historyData.length) {
            const newBalances = new Map();
            historyData.forEach(hd => {
                newBalances.set(hd.txnDate + "T00:00:00", hd.balance);
            });
            let lastBalance = historyData.slice(-1)[0].balance;
            setHardProjections(projData.map(pd => {
                lastBalance += pd.amount;
                newBalances.set(pd.txnDate + "T00:00:00", lastBalance);
                return {...pd, balance: lastBalance, source: pd.schedule ? 'SCHEDULED' : 'AD-HOC'};
            }))
            setSoftProjections(softProjData.map(pd => {
                lastBalance += pd.amount;
                newBalances.set(pd.txnDate + "T00:00:00", lastBalance);
                return {...pd, balance: lastBalance, source: 'SCHEDULED'};
            }))
            const balancesArray = [];
            // @ts-ignore
            for (let [key, value] of newBalances.entries()) {
                balancesArray.push([new Date(key), value]);
            }
            setBalances(balancesArray);
        }
    }, [projData, historyData, softProjData])

    const getSoftProjections = () => {
        let fromDate = hardProjections.slice(-1)[0].txnDate;
        if (softProjData.length) {
            fromDate = softProjData.slice(-1)[0].txnDate;
        }
        ProjectionService.getSoftProjections(account.accountId, fromDate)
            .then(response => {
                const newSoftProjections: Projection[] = response.data.sort(projectionCompare);
                setSoftProjData([...softProjData, ...newSoftProjections]);
            })
            .catch(e => console.error(e));
    }

    function getBalanceStyle(row: ForecastProjection) {
        if (row.balance < 0) {
            return styles.negativeBalance;
        }
        if (account.minimumBalance && row.balance < account.minimumBalance) {
            return styles.warningBalance;
        }
        return styles.positiveBalance;
    }

    return (
        <Fragment>
            {account.accountId && historyData.length && projData.length && (
                <Container maxWidth="xl">
                    <ForecastGraph balances={balances} styles={styles} />
                    <HistoryTable historyData={historyData} styles={styles} virtual={true} />
                    <TableContainer component={Paper} className={styles.section}>
                        <Typography component="h2" className={styles.tableTitle}>3 Months of Projections</Typography>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Date (YYYY-MM-DD)</TableCell>
                                    <TableCell>Account</TableCell>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Source</TableCell>
                                    <TableCell align="right">Amount</TableCell>
                                    <TableCell align="right">Balance</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {hardProjections.map((row) => (
                                    <StyledTableRow key={row.projectionId}>
                                        <TableCell>{row.txnDate}</TableCell>
                                        <TableCell>{row.account.name}</TableCell>
                                        <TableCell>{row.name}</TableCell>
                                        <TableCell>{row.source}</TableCell>
                                        <TableCell align="right">{currencyFormat(row.amount)}</TableCell>
                                        <TableCell align="right" className={getBalanceStyle(row)}>
                                            {currencyFormat(row.balance)}
                                        </TableCell>
                                    </StyledTableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <SoftProjections softProjections={softProjections} styles={styles} getBalanceStyle={getBalanceStyle}
                                     virtual={true} />
                    <Box>
                        <Button variant="contained" onClick={getSoftProjections}>See more projections</Button>
                    </Box>
                </Container>
            )}
        </Fragment>
    )
}

export default VirtualForecast;

import React, {Fragment, useCallback, useEffect, useState} from "react";
import ScheduleService, {EmptySchedule} from "../../services/schedule.service";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import {Box} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import {AgGridColumn, AgGridReact} from "ag-grid-react";
import BtnCellRenderer from "../grid/BtnCellRenderer";
import DateRenderer from "../grid/DateRenderer";
import WhenCellRenderer from "../grid/WhenCellRenderer";
import IconButton from "@material-ui/core/IconButton";
import Add from "@material-ui/icons/Add";
import ScheduleDialog from "./ScheduleDialog";
import useAccountContext from "../../providers/AccountContextProvider";

const ScheduleComponent = () => {

    const {account} = useAccountContext();

    const [data, setData] = useState([]);
    const [gridApi, setGridApi] = useState();
    const [modalSchedule, setModalSchedule] = useState(EmptySchedule);
    const [mode, setMode] = useState('ADD');
    const [open, setOpen] = useState(false);

    const loadSchedules = useCallback(() => {
        if (account.accountId) {
            ScheduleService.getAllForAccount(account.accountId)
                .then(response => {
                    setData(response.data);
                })
                .catch(e => {
                    console.error(e);
                });
        }
    }, [account]);

    useEffect(() => {
        loadSchedules();
    }, [loadSchedules]);

    useEffect(() => {
        if (gridApi && data.length > 0) {
            gridApi.sizeColumnsToFit();
        }
    }, [gridApi, data]);

    const onGridReady = (params) => {
        setGridApi(params.api);
    };

    const onAddRow = () => {
        setModalSchedule({...EmptySchedule, account});
        setMode('ADD');
        setOpen(true);
    }

    const onEditRow = (rowData) => {
        setModalSchedule(rowData);
        setMode('EDIT');
        setOpen(true);
    }

    const onDeleteRow = (rowData) => {
        ScheduleService.del(rowData.scheduleId)
            .then(() => loadSchedules())
            .catch(e => console.error(e));
    }

    const handleCancel = () => {
        setOpen(false);
    }

    const handleSave = (scheduleToSave) => {
        if (mode === 'ADD') {
            ScheduleService.add(scheduleToSave)
                .then(() => loadSchedules())
                .catch(e => console.error(e));
        } else {
            ScheduleService.update(scheduleToSave.scheduleId, scheduleToSave)
                .then(() => loadSchedules())
                .catch(e => console.error(e));
        }
        setOpen(false);
    }

    return (
        <Fragment>
            {modalSchedule && (
                <ScheduleDialog open={open} mode={mode} onCancel={handleCancel} onSave={handleSave}
                                schedule={modalSchedule} />
            )}
            <Container maxWidth="xl">
                <Typography variant="h4">Account Schedules</Typography>
                {account.accountId && (
                    <Box component={Paper} className="ag-theme-alpine-dark" style={{width: "100%"}}>
                        <div style={{display: 'flex', flexDirection: 'row'}}>
                            <div style={{overflow: 'hidden', flexGrow: 1}}>
                                <AgGridReact rowData={data} domLayout={"autoHeight"} onGridReady={onGridReady}
                                             frameworkComponents={{
                                                 btnCellRenderer: BtnCellRenderer,
                                                 dateRenderer: DateRenderer,
                                                 whenCellRenderer: WhenCellRenderer
                                             }}>
                                    <AgGridColumn field="scheduleId" filter={true}/>
                                    <AgGridColumn field="name" sortable={true} filter={true}/>
                                    <AgGridColumn field="amount" sortable={true} />
                                    <AgGridColumn field="scheduleValue" headerName="When"
                                                  cellRenderer={'whenCellRenderer'}/>
                                    <AgGridColumn field="start" sortable={true} filter={true} headerName="Start Date"
                                                  cellRenderer={'dateRenderer'}/>
                                    <AgGridColumn field="end" sortable={true} filter={true} headerName="End Date"
                                                  cellRenderer={'dateRenderer'}/>
                                    <AgGridColumn field="scheduleId" maxWidth={120} headerName="Actions"
                                                  cellRenderer={'btnCellRenderer'} cellRendererParams={{
                                                      onEdit: onEditRow,
                                                      onDelete: onDeleteRow
                                                  }}/>
                                </AgGridReact>
                            </div>
                        </div>
                        <IconButton onClick={onAddRow} component="span" color="primary">
                            <Add/>
                        </IconButton>
                    </Box>
                )}
            </Container>
        </Fragment>
    )
}

export default ScheduleComponent;

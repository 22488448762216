import React from "react";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import {StyledTableRow} from "../../theme";
import {currencyFormat} from "../../utils";
import {History} from "../../services/history.service";

interface HistoryTableProps {
    styles: Record<'section' | 'tableTitle', string>
    historyData: History[]
    virtual?: boolean
}

const HistoryTable = (props: HistoryTableProps) => {

    const {styles, historyData, virtual} = props;

    return (
        <TableContainer component={Paper} className={styles.section}>
            <Typography component="h2" className={styles.tableTitle}>History</Typography>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Date (YYYY-MM-DD)</TableCell>
                        {virtual && (
                            <TableCell>Account</TableCell>
                        )}
                        <TableCell>Name</TableCell>
                        <TableCell align="right">Amount</TableCell>
                        <TableCell align="right">Balance</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {historyData.map((row) => (
                        <StyledTableRow key={row.historyId}>
                            <TableCell>{row.txnDate}</TableCell>
                            {virtual && (
                                <TableCell>{row.account.name}</TableCell>
                            )}
                            <TableCell>{row.name}</TableCell>
                            <TableCell align="right">{currencyFormat(row.amount)}</TableCell>
                            <TableCell align="right">{currencyFormat(row.balance)}</TableCell>
                        </StyledTableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default HistoryTable;

import {AxiosResponse} from "axios";
import {api} from "./utils";
import JwtHelper from "./JwtHelper";

const API_URL = "/auth";

export type Roles = "ROLE_MODERATOR" | "ROLE_USER" | "ROLE_ADMIN";

export type AuthDetails = {
    token: string,
    tokenType: string,
    id: number,
    username: string,
    email: string,
    roles: Roles[]
}

const login = (username: string, password: string, rememberMe: boolean) => {
    return api
        .post(API_URL + "/signin", {
            username,
            password,
            rememberMe
        })
        .then((response: AxiosResponse<AuthDetails>) => {
            if (response.data.token) {
                localStorage.setItem("user", JSON.stringify(response.data));
            }

            return response.data;
        });
};

const logout = () => {
    localStorage.removeItem("user");
};

const register = (username: string, email: string, password: string) => {
    return api.post(API_URL + "/signup", {
        username,
        email,
        password
    });
};

const getCurrentUser = (): null | AuthDetails => {
    const currentUser = localStorage.getItem('user');
    if (currentUser === null) return null;
    const authDetails = JSON.parse(currentUser) as AuthDetails;
    const decodedToken = JwtHelper.decodeToken(authDetails.token);
    const now = new Date();
    if (now.getTime() > (decodedToken.exp * 1000)) {
        logout();
        return null;
    }
    return authDetails;
};

const AuthService = {
    login,
    logout,
    register,
    getCurrentUser
};

export default AuthService;

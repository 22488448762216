import React, {Fragment, useCallback, useEffect, useState} from "react";
import Container from '@material-ui/core/Container';
import AccountService, {Account} from "../../services/account.service";
import {Box} from "@material-ui/core";
import {AgGridColumn, AgGridReact} from "ag-grid-react";
import Paper from "@material-ui/core/Paper";
import {GridApi, GridReadyEvent} from "ag-grid-community";
import BtnCellRenderer from "../grid/BtnCellRenderer";
import AccountDialog from "./AccountDialog";
import IconButton from "@material-ui/core/IconButton";
import Add from "@material-ui/icons/Add";
import Typography from "@material-ui/core/Typography";
import CheckboxRenderer from "../grid/CheckboxRenderer";


const Accounts = () => {

    const [data, setData] = useState<Account[]>([]);
    const [gridApi, setGridApi] = useState<GridApi | null>(null);
    const [modalAccount, setModalAccount] = useState<Account>({accountId: 0, name: ''});
    const [modalMode, setModalMode] = useState<'ADD' | 'EDIT'>('ADD');
    const [modalOpen, setModalOpen] = useState(false);

    const loadAccounts = useCallback(() => {
        AccountService.getAllWithRetired()
            .then(response => {
                setData(response.data);
            })
            .catch(e => {
                console.error(e);
            });
    }, [])

    const onGridReady = (params: GridReadyEvent) => {
        setGridApi(params.api);
    };

    useEffect(() => {
        loadAccounts();
    }, [loadAccounts]);

    useEffect(() => {
        if (gridApi) {
            gridApi.sizeColumnsToFit();
        }
    }, [gridApi]);

    const onAddRow = () => {
        setModalAccount({accountId: 0, name: ''});
        setModalMode('ADD');
        setModalOpen(true);
    }

    const onEditRow = (rowData: Account) => {
        setModalAccount(rowData)
        setModalMode('EDIT');
        setModalOpen(true);
    };

    const onDeleteRow = (rowData: Account) => {
        AccountService.del(rowData.accountId)
            .then(() => {
                const filtered = data.filter(a => a.accountId !== rowData.accountId);
                setData([...filtered]);
                loadAccounts();
            })
            .catch(e => {
                console.error(e);
            })
    }

    const handleCancel = () => {
        setModalOpen(false);
    }

    const handleSave = (accountToSave: Account) => {
        if (modalMode === 'ADD') {
            AccountService.add(accountToSave)
                .then(response => {
                    setData([...data, response.data]);
                    loadAccounts();
                })
                .catch(e => {
                    console.error(e);
                });
        } else {
            AccountService.update(accountToSave.accountId, accountToSave)
                .then(() => {
                    const filtered = data.filter(a => a.accountId !== accountToSave.accountId);
                    setData([...filtered, accountToSave]);
                    loadAccounts();
                })
                .catch(e => {
                    console.error(e);
                });
        }
        setModalOpen(false);
    }

    return (
        <Fragment>
            {modalAccount && (
                <AccountDialog modalOpen={modalOpen} mode={modalMode} onCancel={handleCancel} onSave={handleSave}
                               account={modalAccount} accounts={data} />
            )}
            <Container maxWidth="lg">
                <Typography variant="h4">Accounts</Typography>
                <Box component={Paper} className="ag-theme-alpine-dark" style={{ width: "100%"}}>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div style={{ overflow: 'hidden', flexGrow: 1 }}>
                            <AgGridReact rowData={data} domLayout={"autoHeight"} onGridReady={onGridReady}
                                         frameworkComponents={{
                                             btnCellRenderer: BtnCellRenderer,
                                             chkRenderer: CheckboxRenderer
                                         }}>
                                <AgGridColumn field="accountId" sortable={true} filter={true}/>
                                <AgGridColumn field="name" sortable={true} filter={true} />
                                <AgGridColumn field="minimumBalance" sortable={true} filter={true} />
                                <AgGridColumn field="virtual" cellRenderer={'chkRenderer'}/>
                                <AgGridColumn field="retired" cellRenderer={'chkRenderer'}/>
                                <AgGridColumn field="parentAccount.name" headerName="Parent" />
                                <AgGridColumn field="accountId"
                                              maxWidth={120}
                                              headerName="Actions"
                                              cellRenderer={'btnCellRenderer'}
                                              cellRendererParams={{
                                                  onEdit: onEditRow,
                                                  onDelete: onDeleteRow
                                              }}/>
                            </AgGridReact>
                        </div>
                    </div>
                    <IconButton onClick={onAddRow} component="span" color="primary">
                        <Add/>
                    </IconButton>
                </Box>
            </Container>
        </Fragment>
    )
}

export default Accounts;

import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import ProjectionService from '../services/projection.service';
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import {TableCell} from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import {StyledTableRow} from "../theme";
import {currencyFormat} from "../utils";


const useStyles = makeStyles((theme) => ({
    image: {
        width: '100%',
    },
    intro: {
        marginLeft: theme.spacing() * 4,
        fontSize: "large",
        marginBottom: theme.spacing() * 10
    },
    tableContainer: {
        backgroundColor: '#133062'
    },
    dueToday: {
        color: '#21E6C1'
    },
    pastDue: {
        color: 'tomato'
    }
}));

const Home = () => {
    const classes = useStyles();

    const [projections, setProjections] = useState([]);

    useEffect(() => {
        ProjectionService.getAllSoon().then(response => {
            const sortedProjections = response.data
                .sort((a, b) => a.txnDate.localeCompare(b.txnDate)
                    || a.account.name.localeCompare(b.account.name)
                    || b.amount - a.amount);
            setProjections(sortedProjections);
        });
    }, []);

    const styleForDue = (txnDate) => {
        const todayDate = new Date();
        const strDate = `${todayDate.getFullYear()}-${("0" + (todayDate.getMonth() + 1)).slice(-2)}-${("0" + (todayDate.getDate() + 1)).slice(-2)}`
        if (strDate.localeCompare(txnDate) === 0)
            return classes.dueToday;
        if (strDate.localeCompare(txnDate) > 0)
            return classes.pastDue;
        return '';
    }

    return (
        <Container maxWidth="xl">
            <Grid container spacing={3}>
                <Grid item xs={12} md={5}>
                    <img src="dog-2467149_640.jpg" alt="CPA Dog" className={classes.image}/>
                </Grid>
                <Grid item xs={12} md={7}>
                    <Typography variant="h3">My dog is a CPA</Typography>
                    <Typography variant="body1" className={classes.intro}>
                        He's smarter than you about money, and he's telling you that you need to save before you buy
                        instead of buying before you save. Listen to him. He is wise.
                    </Typography>
                    {projections.length !== 0 && (
                        <>
                            <Typography variant="h4">Upcoming Projections</Typography>
                            <TableContainer component={Paper} className={classes.tableContainer}>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Date</TableCell>
                                            <TableCell>Account</TableCell>
                                            <TableCell>Name</TableCell>
                                            <TableCell>Amount</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {projections.map(row => (
                                            <StyledTableRow key={row.projectionId}>
                                                <TableCell className={styleForDue(row.txnDate)}>{row.txnDate}</TableCell>
                                                <TableCell>{row.account.name}</TableCell>
                                                <TableCell>{row.name}</TableCell>
                                                <TableCell>{currencyFormat(row.amount)}</TableCell>
                                            </StyledTableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </>
                    )}
                </Grid>
            </Grid>
        </Container>
    );
};

export default Home;

import React, {Component} from "react";
import IconButton from "@material-ui/core/IconButton";
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete"

interface BtnCellRendererProps {
    onEdit: any;
    onDelete: any;
    value: any;
    data: any;
}

class BtnCellRenderer extends Component<BtnCellRendererProps> {
    constructor(props: BtnCellRendererProps) {
        super(props);
        this.editClickedHandler = this.editClickedHandler.bind(this);
        this.deleteClickedHandler = this.deleteClickedHandler.bind(this);
    }
    editClickedHandler() {
        this.props.onEdit(this.props.data);
    }
    deleteClickedHandler() {
        this.props.onDelete(this.props.data);
    }
    render() {
        return (
            <>
                <IconButton onClick={this.editClickedHandler} component="span" size="small" color="primary">
                    <Edit/>
                </IconButton>
                <IconButton onClick={this.deleteClickedHandler} component="span" size="small" color="secondary">
                    <Delete/>
                </IconButton>
            </>
        )
    }
}

export default BtnCellRenderer;
